export const environment = {
  production: false,
  develop: false,
  staging: true,
  cmsURL: 'https://catalog.staging.extra.ge/',
  customersURL: 'https://customer.staging.extra.ge/',
  reportingURL: 'https://reporting.staging.extra.ge/',
  paymentURL: 'https://payment.staging.extra.ge/',
  orderingURL: 'https://ordering.staging.extra.ge/',
  basketUrl: 'https://basket.staging.extra.ge/',
  apiUrl: 'https://identity.staging.extra.ge/',
  emailUrl: 'https://notification.staging.extra.ge/',
  issuerUrl: 'https://identity.staging.extra.ge',
  marketingURL: 'https://marketing.staging.extra.ge/',
  tradeInUrl: 'https://tradein-api.staging.extra.ge/',
  cdnUrl: 'https://cdn.staging.extra.ge/',
  baseUrl: 'https://staging.extra.ge',
  juridicalUrl: 'https://juridicalidentity.staging.extra.ge/',
  juridicalIssuerUrl: 'https://juridicalidentity.staging.extra.ge',
  adultCallback: 'catalog/satamashoebi',
  mercuryURL: 'https://mercury.staging.extra.ge/',
  'wall-e': 'https://wall-e.staging.extra.ge/',
  deliveryCalculationApi: 'https://delivery-datecalculation-api.staging.extra.ge/',
  deliveryIntergrationURL: 'https://delivery-integration-api.staging.extra.ge/',
  mixpanelApiURL: 'https://mixpanel-api.staging.extra.ge/',
  dynamicYieldURL: 'https://direct.dy-api.eu/',
  dynamicYieldCollectURL: 'https://direct-collect.dy-api.eu/',
  dynamicYieldApiKey: 'e0f6b617006c4957e17717a2f8dd5e44c889859be2104609e1e8e32a3002b723',
  recaptchaSiteKey: '6Ld3F-EqAAAAAF3Z8uc0BEiD1hQdISRYBvjbCF33'
};
